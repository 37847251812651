import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  reset() {
    this.element.reset()
  }

  submitOnEnter(event) {
    if (event.key === "Enter") {
      event.preventDefault()
      this.element.requestSubmit()
    }
  }
}