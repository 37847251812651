import { Controller } from "@hotwired/stimulus";
import "preline";

export default class extends Controller {
  connect() {
    this.initPreline();
  }

  initPreline() {
    if (typeof HSStaticMethods !== "undefined" && HSStaticMethods.autoInit) {
      try {
        HSStaticMethods.autoInit();
      } catch (error) {
        console.error("Error initializing Preline:", error);
      }
    } else {
      console.warn("HSStaticMethods.autoInit is not available");
    }
  }

  reinitialize() {
    this.initPreline();
  }
}
