import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["show", "form"]

  showForm() {
    this.showTarget.classList.add("hidden")
    this.formTarget.classList.remove("hidden")
    this.formTarget.querySelector("input").focus()
  }

  hideForm(event) {
    if (event.target.value !== "") {
      event.target.form.requestSubmit()
    }
    this.showTarget.classList.remove("hidden")
    this.formTarget.classList.add("hidden")
  }
}
