import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["option"]
  static values = {
    url: String,
    token: String
  }

  select(event) {
    const theme = event.currentTarget.dataset.theme

    // Update outline on selected theme
    this.optionTargets.forEach(option => {
      option.classList.remove("!outline-base-content")
    })
    event.currentTarget.classList.add("!outline-base-content")

    // Send AJAX request to update theme
    fetch(this.urlValue, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.tokenValue
      },
      body: JSON.stringify({ theme })
    })
      .then(response => {
        if (response.ok) {
          window.location.reload()
        }
      })
  }
} 