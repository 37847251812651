import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["preview", "removeField", "fileInput"]

  connect() {
    this.fileInputTarget.addEventListener('change', this.updatePreview.bind(this))
  }

  updatePreview() {
    const file = this.fileInputTarget.files[0]
    if (file && this.isImageFile(file)) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.previewTarget.innerHTML = `<img src="${e.target.result}" class="w-full h-full object-cover rounded-full" alt="Avatar preview">`
      }
      reader.readAsDataURL(file)
    } else if (file) {
      // If a non-image file was selected, reset the input and show an alert
      this.fileInputTarget.value = ""
      alert("Please select a valid image file (JPEG, PNG, GIF, or WebP).")
    }
  }

  remove() {
    const defaultSvg = `<svg class="shrink-0 size-7" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"><rect width="18" height="18" x="3" y="3" rx="2" ry="2"/><circle cx="9" cy="9" r="2"/><path d="m21 15-3.086-3.086a2 2 0 0 0-2.828 0L6 21"/></svg>`

    this.previewTarget.innerHTML = defaultSvg
    this.removeFieldTarget.value = "1"
    this.fileInputTarget.value = ""
  }

  isImageFile(file) {
    const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp']
    return acceptedImageTypes.includes(file.type)
  }
}
