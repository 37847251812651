import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select", "customContainer"]

  connect() {
    this.toggleCustomUnit()
  }

  toggleCustomUnit() {
    const isCustom = this.selectTarget.value === 'custom'
    this.customContainerTarget.classList.toggle('hidden', !isCustom)
  }

  selectChanged() {
    this.toggleCustomUnit()
  }
}
