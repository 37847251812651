import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["milestoneContainer"]

  async fetchMilestones(event) {
    const goalId = event.target.value
    const selectElement = this.milestoneContainerTarget.querySelector('select')

    // Clear milestone selection
    selectElement.value = ""

    if (!goalId) {
      this.milestoneContainerTarget.style.display = "none"
      return
    }

    const response = await fetch(`/goals/${goalId}/milestones_for_select`)
    const milestones = await response.json()

    if (milestones.length > 0) {
      selectElement.innerHTML = '<option value="">Select a milestone</option>'
      milestones.forEach(milestone => {
        selectElement.innerHTML += `<option value="${milestone.id}">${milestone.title}</option>`
      })
      this.milestoneContainerTarget.style.display = "block"
    } else {
      this.milestoneContainerTarget.style.display = "none"
    }
  }
}