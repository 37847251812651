import ContentLoader from "@stimulus-components/content-loader"

export default class extends ContentLoader {
  static targets = ["container"]
  static values = {
    maxFetchCount: { type: Number, default: 60 }
  }

  connect() {
    super.connect()
    this.fetchCount = 0
  }

  fetch() {
    super.fetch()
    this.fetchCount++
    this.checkContent()
    this.checkFetchCount()
  }

  checkContent() {
    if (!this.containerTarget.querySelector('.loading-content')) {
      this.stopRefreshing()
    }
  }

  checkFetchCount() {
    if (this.fetchCount >= this.maxFetchCountValue) {
      this.stopRefreshing()
    }
  }
}
